import React from "react"
import Seo from "../components/seo"
import NewsItem from "../components/news-item"
import Link from "../components/link"
import { FormattedMessage, injectIntl } from "react-intl"
import { useStaticQuery, graphql } from "gatsby"
import Teaser from "../components/teaser"
import Stat from "../components/stat"
import EnvironmentStats from "../components/stats-environment"
import Sustainability from "../components/sustainability"
import Download from "../components/download"

const HomePage = ({ data, intl }) => {
  const {
    heroImages,
    sustainabilityImage,
    materialImage,
    energyImage,
    resourcesImage,
    digitalisationImage,
    logisticsImage,
    mobilityImage,
  } = useStaticQuery(
    graphql`
      query HeroImageQuery {
        heroImages: allFile(
          filter: { relativePath: { glob: "home-heroes/*.jpg" } }
          sort: { fields: name, order: ASC }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(quality: 95, layout: FULL_WIDTH)
            }
          }
        }
        sustainabilityImage: file(
          relativePath: { eq: "home-sustainability/teaser.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        materialImage: file(
          relativePath: { eq: "home-sustainability/material.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        energyImage: file(
          relativePath: { eq: "home-sustainability/energy.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        resourcesImage: file(
          relativePath: { eq: "home-sustainability/resources.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        digitalisationImage: file(
          relativePath: { eq: "home-sustainability/digitalisation.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        logisticsImage: file(
          relativePath: { eq: "home-sustainability/logistics.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
        mobilityImage: file(
          relativePath: { eq: "home-sustainability/mobility.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95, layout: FULL_WIDTH)
          }
        }
      }
    `
  )

  const { allSanityPost } = data
  const statClassName = `col xs:w-1/2 lg:w-1/4`

  return (
    <>
      <Seo
        id="home"
        description={intl.formatMessage({ id: "page.home.description" })}
        keywords={intl.formatMessage({ id: "page.home.keywords" })}
      />
      <Teaser image={heroImages.nodes}>
        <h1>
          <FormattedMessage id="home.hero.title" />
        </h1>
        <p>
          <FormattedMessage id="home.hero.copy" />
        </p>
        <Link to="/company" className="accessory">
          <FormattedMessage id="home.hero.explore" />
        </Link>
      </Teaser>

      {allSanityPost.edges && allSanityPost.edges.length && (
        <section className="container section lg:flex">
          <div className="col md:w-1/3 lg:w-1/4">
            <h2 className="section-heading">
              <FormattedMessage id="title.news" />
            </h2>
          </div>
          <div className="col lg:w-3/4">
            {allSanityPost.edges.map(({ node }) => (
              <NewsItem key={node.id} node={node} />
            ))}
            <Link to="/news" className="accessory">
              <FormattedMessage id="page.home.archive" />
            </Link>
          </div>
        </section>
      )}

      <section className="container section">
        <div className="col md:w-1/3 lg:w-1/4">
          <h2 className="section-heading">
            <FormattedMessage id="title.stats" />
          </h2>
        </div>
        <div className="col">
          <ul className="container xs:flex xs:flex-wrap">
            <li className={statClassName}>
              <Stat label="employees" />
            </li>
            <li className={statClassName}>
              <Stat label="tons-per-month" />
            </li>
            <li className={statClassName}>
              <Stat prefix="ø" label="dimension-range" />
            </li>
            <li className={statClassName}>
              <Stat label="production-space" />
            </li>
            <li className="col xs:w-full lg:w-1/4">
              <Stat label="tools-per-month" />
            </li>
          </ul>
        </div>
      </section>
      <Teaser image={sustainabilityImage} id="sustainability">
        <h1>
          <FormattedMessage id="page.home.sustainability.title" />
        </h1>
        <p>
          <FormattedMessage id="page.home.sustainability.paragraph" />
        </p>
        <Download
          extension="pdf"
          fileName={intl.formatMessage({
            id: "page.home.sustainability.download.link",
          })}
          title={intl.formatMessage({
            id: "page.home.sustainability.download",
          })}
          size="824320"
        />
      </Teaser>
      <section className="container section lg:text-lg">
        <h2 className="col mb-xl md:w-2/3">
          <FormattedMessage id="page.home.sustainability.subheading" />
        </h2>
        <Sustainability image={materialImage} type="material">
          <Stat label="co2-savings-percent" icon="leaf" />
        </Sustainability>
        <Sustainability image={energyImage} type="energy">
          <EnvironmentStats />
        </Sustainability>
        <Sustainability image={resourcesImage} type="resources">
          <Stat label="resources" icon="sync" />
        </Sustainability>
        <Sustainability image={digitalisationImage} type="digitalisation" >
          <Stat label="digitalisation" icon="copy" prefix="<" />
        </Sustainability>
        <Sustainability image={logisticsImage} type="logistics" >
          <Stat label="logistics" icon="truck" /> 
        </Sustainability>
        <Sustainability image={mobilityImage} type="mobility" >
          <Stat label="mobility" icon="charging-station" /> 
        </Sustainability>
      </section>
      <section className="container section">
        <div className="sm:flex lg:mx-auto lg:max-w-narrow">
          <h2 className="sr">
            <FormattedMessage id="title.services" />
          </h2>
          <div className="col sm:w-1/2">
            <h3 className="h2">
              <FormattedMessage id="title.wire" />
            </h3>
            <p>
              <FormattedMessage id="page.wire.description" />
            </p>
            <Link to="/wire" className="accessory">
              <FormattedMessage id="global.explore" />
            </Link>
          </div>
          <div className="col sm:w-1/2">
            <h3 className="h2">
              <FormattedMessage id="title.forming-tools" />
            </h3>
            <p>
              <FormattedMessage id="page.forming-tools.description" />
            </p>
            <Link to="/forming-tools" className="accessory">
              <FormattedMessage id="global.explore" />
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default injectIntl(HomePage)
