import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/de"
import IndexPageBase from "../templates/index"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <IndexPageBase data={this.props.data} locale="de" />
      </Layout>
    )
  }
}

export default IndexPage

export const query = graphql`
  query IndexDeQuery {
    allSanityPost(
      limit: 3
      sort: { order: DESC, fields: publishedAt }
      filter: { title: { de: { ne: null } } }
    ) {
      edges {
        node {
          id
          title {
            content: de
          }
          slug {
            current
          }
          link
          has_page
          excerpt {
            content: de
          }
          mainImage {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          publishedAt(locale: "de", fromNow: true)
        }
      }
    }
  }
`
