import React from "react"
import Link from "./link"
import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedMessage } from "react-intl"

class NewsItem extends React.Component {
  render() {
    const { node } = this.props

    return (
      <article className="container mb-md sm:flex">
        <div className="col mb-sm sm:w-1/2">
          <figure className="bg-gray-lightest pt-3/2 relative">
            {node.mainImage && (
              <div className="absolute left-0 top-0 h-full w-full">
                <GatsbyImage
                  image={node.mainImage.asset.gatsbyImageData}
                  alt={node.title.content}
                />
              </div>
            )}
          </figure>
        </div>
        <div className="col sm:w-1/2">
          <h3 className="h2 mt-0">{node.title.content}</h3>
          <p>{node.excerpt.content}</p>
          {node.has_page && (
            <Link to={`/news/${node.slug.current}`} className="accessory">
              <FormattedMessage id="global.explore" />
            </Link>
          )}
          {!node.has_page && node.link && (
            <Link to={node.link} className="accessory">
              <FormattedMessage id="global.explore" />
            </Link>
          )}
        </div>
      </article>
    )
  }
}

export default NewsItem
