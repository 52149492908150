import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedMessage, injectIntl } from "react-intl"

const Sustainability = ({ image, children, type, intl }) => {
  return (
    <div className="mb-lg lg:flex">
      <div className="col md:w-1/3 lg:w-1/4">
        <h2 className="section-heading">
          <FormattedMessage id={`page.home.sustainability.${type}.title`} />
        </h2>
      </div>
      <div className="flex flex-col lg:w-3/4">
        <div className="flex flex-col sm:flex-row">
          <figure className="col sm:w-1/2 mb-sm">
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={intl.formatMessage({
                id: `page.home.sustainability.${type}.title`,
              })}
            />
          </figure>
          <p className="col sm:w-1/2">
            <FormattedMessage
              id={`page.home.sustainability.${type}.paragraph`}
            />
          </p>
        </div>
        <div className="col">{children}</div>
      </div>
    </div>
  )
}

export default injectIntl(Sustainability)
